import { graphql } from "gatsby";
import * as React from "react";
import Feature from "../components/feature/feature";
import Layout from "../components/layout";
import WorkList from "../components/works/work-list";
import { FeatureModel } from "../models/FeatureModel";
import { ListItemModel } from "../models/ListItemModel";

type DataProps = {
  data: {
    allWpWork: {
      nodes: ListItemModel[];
    };
    wpPage: {
      select_feature: {
        features: FeatureModel[];
      };
    };
  };
};

const IndexPage = ({ data }: DataProps) => {
  // console.log(data);

  const features = data.wpPage.select_feature.features;

  const [headerColor, setHeaderColor] = React.useState(() => {
    if (features.length > 0) {
      return features[0].featureHeaderColor ?? "black";
    } else {
      return "black";
    }
  });
  const [scrolled, setScrolled] = React.useState(false);
  const divRef = React.useRef<HTMLDivElement>(null);

  const changeHeaderColor = (value: "white" | "black") => {
    if (scrolled) {
      setHeaderColor("black");
    } else {
      setHeaderColor(value);
    }
  };

  const onScroll = React.useCallback(() => {
    const target = divRef.current;
    if (target) {
      const offset = target.offsetTop;
      const scroll = window.scrollY;
      const scrolled = offset <= scroll;
      setScrolled(scrolled);
      if (scrolled && headerColor === "white") {
        setHeaderColor("black");
      }
    }
  }, [headerColor]);

  React.useEffect(() => {
    document.addEventListener("scroll", onScroll, { passive: true });
    return () => {
      document.removeEventListener("scroll", onScroll);
    };
  }, [headerColor]);

  const items: ListItemModel[] = data.allWpWork.nodes;

  return (
    <Layout ogType="website" textColor={headerColor}>
      <main>
        <Feature features={features} onChange={changeHeaderColor} />
        <div ref={divRef}></div>
        <div style={{ margin: "20px", marginTop: "2rem" }}>
          <div style={{ padding: "26px 0" }}>RECENT PROJECT</div>
          <WorkList path="/work/" items={items} />
        </div>
      </main>
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query {
    allWpWork(sort: { fields: [date], order: DESC }, limit: 9) {
      nodes {
        title
        title_en {
          titleEn
        }
        slug
        date
        thumbnail {
          thumbnailContent {
            type
            movie {
              guid
            }
            images {
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                    fluid {
                      originalImg
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    wpPage(slug: { eq: "feature" }) {
      slug
      select_feature {
        features {
          featureHeaderColor
          featureArticle {
            ... on WpWork {
              slug
              contentTypeName
              blocks {
                block {
                  contents {
                    usingFeature
                    type
                    picture {
                      localFile {
                        childImageSharp {
                          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                          fluid {
                            originalImg
                          }
                        }
                      }
                    }
                    pictureMobile {
                      localFile {
                        childImageSharp {
                          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                          fluid {
                            originalImg
                          }
                        }
                      }
                    }
                    movieUrl
                    movie {
                      guid
                    }
                    movieMobile {
                      guid
                    }
                  }
                }
              }
            }
            ... on WpLab {
              slug
              contentTypeName
              blocks {
                block {
                  contents {
                    usingFeature
                    type
                    picture {
                      localFile {
                        childImageSharp {
                          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                          fluid {
                            originalImg
                          }
                        }
                      }
                    }
                    pictureMobile {
                      localFile {
                        childImageSharp {
                          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                          fluid {
                            originalImg
                          }
                        }
                      }
                    }
                    movieUrl
                    movie {
                      guid
                    }
                    movieMobile {
                      guid
                    }
                  }
                }
              }
            }
            ... on WpNews {
              slug
              contentTypeName
              blocks {
                block {
                  contents {
                    usingFeature
                    type
                    picture {
                      localFile {
                        childImageSharp {
                          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                          fluid {
                            originalImg
                          }
                        }
                      }
                    }
                    pictureMobile {
                      localFile {
                        childImageSharp {
                          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                          fluid {
                            originalImg
                          }
                        }
                      }
                    }
                    movieUrl
                    movie {
                      guid
                    }
                    movieMobile {
                      guid
                    }
                  }
                }
              }
            }
            ... on WpAboutArticle {
              slug
              contentTypeName
              blocks {
                block {
                  contents {
                    usingFeature
                    type
                    picture {
                      localFile {
                        childImageSharp {
                          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                          fluid {
                            originalImg
                          }
                        }
                      }
                    }
                    pictureMobile {
                      localFile {
                        childImageSharp {
                          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                          fluid {
                            originalImg
                          }
                        }
                      }
                    }
                    movieUrl
                    movie {
                      guid
                    }
                    movieMobile {
                      guid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
